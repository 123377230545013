@import 'Styles/includes';

/**
*
* ExpandableCard
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.ExpandableCard {
    $root: &;

    border-left: 2px solid transparent;

    &--Open {
        border-left: 2px solid $expandable-border;
    }

    &__Header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 24px;
        border: 1px solid $gray-10;
        background-color: white;
        cursor: pointer;
    }

    &__Title {
        padding-right: 10px;
        font-size: 1.6rem;
        font-weight: 600;
        line-height: 19px;
    }

    &__ToggleIcon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 27px;
        min-width: 27px;
        width: 27px;
        border: 1px solid #d8d8d8;
        border-radius: 50%;
        background: white;

        &::before {
            content: '';
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url('#{$publicpath}img/icon-arrow-down.svg');
            width: 12px;
            height: 12px;
            display: inline-block;
            background-position: center;
        }

        #{$root}__Header:hover & {
            background: $gray-10;
        }

        &--Open {
            background-color: $expandable-toggle-active;
            border: none;

            &::before {
                background-image: url('#{$publicpath}img/icon-arrow-down--white.svg');
                transform: rotate(180deg);
            }

            #{$root}__Header:hover & {
                background: $expandable-toggle-active;
            }
        }
    }

    &__Content {
        border: 1px solid #ececec;
        border-top: none;
    }
}
