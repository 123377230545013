@import 'Styles/includes';

/**
*
* ArrangementInformationNonEditorConfigurator
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.ArrangementInformationNonEditorConfigurator {
    $root: &;

    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    &--Loading {
        position: relative;
        opacity: 0.5;
        pointer-events: none;
        user-select: none;
    }

    &__LoaderWrapper {
        position: absolute;
        top: 54px;
        left: calc(50% - 35px / 2);
        width: 35px;
        height: 35px;
        margin: 0 auto;
    }

    &__Loader {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background-color: transparent;
        border: 5px solid rgba($red, 0.5);
        border-top-color: $red;

        &:global {
            animation: 1s spin linear infinite;
        }
    }

    &__DownloadButtonWrapper {
        display: flex;
        justify-content: center;
    }

    &__Submit {
        font-family: proxima-nova, Arial;
        border-radius: 4px;
        cursor: pointer;
        background-color: $red;
        border: none;
        margin: 0;
        font-size: 1.4rem;
        font-weight: 600;
        color: white;
        padding: 16px 48px;
        display: inline-block;
        text-align: center;
        outline: none;

        &:hover {
            background: $red-60;
            @media (hover: none) {
                background: $red;
            }
        }
    
        &:active {
            background: $red-30;
        }
    }
}
