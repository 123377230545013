@import 'Styles/includes';

/**
*
* CardContactPerson
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.CardContactPerson {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 32px 15px 24px 15px;
    box-shadow: 0 0 4px 0 rgba(black, 0.15);
    border-radius: 4px;
    height: 100%;

    @include media(md) {
        padding: 48px 15px 32px 15px;
    }

    &__Image {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        margin-bottom: 16px;

        @include media(md) {
            margin-bottom: 28px;
        }
    }

    &__Texts {
        margin-bottom: 12px;
        font-size: 1.4rem;
        text-align: center;
        line-height: 1.5;

        @include media(md) {
            margin-bottom: 16px;
        }
    }

    &__Name {
        font-weight: bold;
        font-size: 1.6rem;
    }

    &__Link {
        display: block;
        text-decoration: none;
        color: $red;
        width: auto;
        word-break: break-all;
        line-height: 1.25;
        display: inline-flex;
        align-items: center;
        position: relative;
        margin-bottom: 4px;
        margin-left: -14px;

        &:hover {
            text-underline-offset: 2px;
            text-decoration-thickness: 1px;
            text-decoration: underline;
        }

        &:before {
            content: '';
            display: inline-block;
            width: 14px;
            height: 14px;
            margin-right: 8px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
        }
        
        &--Email {
            text-transform: lowercase;
            &:before {
                background-image: url('#{$publicpath}img/icon-email.svg');
            }
        }

        &--Phone {
            &:before {
                background-image: url('#{$publicpath}img/icon-old-phone.svg');
            }
        }

        &--MobilePhone {
            &:before {
                background-image: url('#{$publicpath}img/icon-phone.svg');
            }
        }

        // &--Location {
        //     &:before {
        //         background-image: url('#{$publicpath}img/icon-location-black.svg');
        //         height: 8px;
        //         padding-bottom: 8px;
        //     }
        // }
    }

    &__Links {
        font-size: 1.4rem;
        text-align: center;
        line-height: 1.5;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__Meta {
        display: inline-flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
        font-size: 1.1rem;
        letter-spacing: 0.8px;
        text-transform: uppercase;
        margin-top: 16px;

        @include media(md) {
            margin-top: 32px;
        }
    }

    &__Column {
        display: flex;
        flex-direction: column;
        width: calc(50% - 4px);

        &--Department {
            border: none;
            margin-left: 0;
            text-align: right;
        }

        &--Place {
            border-left: 1px solid $text;
            margin-left: 8px;
        }
    }

    &__Row {
        margin-bottom: 6px;

        &--Place {
            margin-left: 8px;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}
