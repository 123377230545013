@import 'Styles/includes';

/**
*
* ExpandableContactPerson
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.ExpandableContactPerson {
    $root: &;

    background: white;
    border-top: 1px solid $gray-10;
    margin: 0 24px;

    &__Image {
        position: relative;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        margin-right: 24px;
        overflow: hidden;
    }

    &__FlexWrapper {
        display: flex;
        align-items: center;
    }

    &__Header {
        min-height: 92px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 24px 16px 0;

        #{$root}--HasContent & {
            cursor: pointer;
        }
    }

    &__Title {
        padding-right: 10px;
        font-size: 1.6rem;
        font-weight: 600;
        line-height: 19px;
    }

    &__ToggleIcon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 27px;
        min-width: 27px;
        width: 27px;
    }

    &__Arrow {
        width: 10px;

        #{$root}--Open & {
            transform: rotate(180deg);
        }
    }

    &__Content {
        display: block;
        background: white;
    }

    &__Wysiwyg {
        padding-bottom: 15px;

        @include media(lg) {
            font-size: 1.8rem;
        }

        > *:first-child {
            margin-top: 0;
        }

        > *:last-child {
            margin-bottom: 0;
        }
    }
}
