@import 'Styles/includes';

/**
*
* CardImage
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.CardImage {
    border-radius: 4px;
    overflow: hidden;

    &__Image {
        width: 100%;
        display: block;
        background-size: cover;
        background-position: 50%;
        background-repeat: no-repeat;
        padding-top: 66.64%;
        background-color: rgba($gray-10, 0.5);
    }

    &__Container {
        background: $gray-10;
        padding: 16px 16px 24px;

        &--NoDescription {
            padding: 16px;
        }
    }

    &__Description {
        color: $gray-40;
        font-size: 1.4rem;
        line-height: 1.4;
        margin-bottom: 6px;
    }

    &__Photographer {
        opacity: 0.75;
        font-size: 1.2rem;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 1px;
    }
}
