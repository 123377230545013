@import 'Styles/includes';

.Modal {
    $root: &;

    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;

    &__Inner {
        width: 100%;
        min-height: 400px;
        padding: 25px 10px 60px;
        margin: 0 5px;
        border-radius: 6px;
        background-color: white;
        position: relative;

        @include media(xs){
            min-height: 390px;
        }

        @include media(sm) {
            max-width: 600px;
            margin: 0;
            padding: 24px 24px 50px 24px;
        }
    }

    &__Title {
        font-size: 1.8rem;
        font-weight: 700;
        margin-bottom: 15px;
        text-align: center;

         @include media(sm) {
            font-size: 2rem;
        }
    }

    &__Desc {
        font-size: 1.2rem;
        color: $gray-70;
        text-align: center;
        line-height: 1.2;
        max-width: 400px;
        margin: 0 auto 16px auto;

        a {
            color: $button-bg; 
            text-decoration: underline;
        }

         @include media(sm) {
            font-size: 1.6rem;
            max-width: none;
            
        }
    }

    &__Close {
        position: absolute;
        top: 8px;
        right: 8px;
        display: block;
        height: 20px;
        width: 20px;
        background-image: url('../#{$publicpath}img/close--fontawsome.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        background-color: transparent;
        border: none;
        cursor: pointer;

        @include media(sm) {
            top: 11px;
            right: 11px;
        }
    }

    &__Tabs {
        display: flex;
        margin-bottom: 14px;
        width: 100%;
    }

    &__Tab {
        padding: 10px;
        border: none;
        border-bottom: 1px solid $gray-20;
        background-color: $gray-15;
        font-size: 1.2rem;
        font-weight: bold;
        cursor: pointer;
        width: 50%;
        color: $gray-50;
        transition: all .2s ease-in-out;
        hyphens: auto;
        
        &:first-of-type {
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
        }

        &:last-of-type {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
        }

        &:hover {
            @include media(sm) {
                background-color: #e1e1e1;
                color: $gray-70;
            }
        }

        &--Active {
            pointer-events: none;
            border-bottom: none;
            background-color: $button-bg;
            color: white;
        }
    }

    &__Products {
        &--HideTabs {
            margin-top: 20px;
        }
    }

    &__Categories {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-left: 8px;

        #{$root}__Products--HideTabs & {
            display: none;
        }
    }

    &__Category {
        display: block;
        padding: 6px 10px;
        border: none;
        background-color: $gray-10;
        color: $gray-50;
        cursor: pointer;
        border-radius: 3px;
        font-size: 1rem;
        font-weight: bold;
        margin-right: 8px;
        margin-bottom: 8px;
        white-space: nowrap;
        transition: all .2s ease-in-out;

        @include media(sm) {
            font-size: 1.2rem;
        }

        &:hover {
            @include media(sm) {
                background-color: #e1e1e1;
                color: $gray-70;
            }
        }

        &--Active {
            background-color: $button-bg;
            pointer-events: none;
            color: white;
        }
    }

    &__TableWrapper {
        max-height: 150px;
        overflow-y: auto;
        scrollbar-color: $button-bg $gray-10;
        margin-top: 6px;
        border-bottom: 1px solid $gray-10;;
    }

    &__Table {
        width: 100%;
        padding: 8px;
        border-top: 1px solid $gray-10;
        border-left: 1px solid $gray-10;
        border-right: 1px solid $gray-10;
        border-radius: 4px;

        thead {
            background-color: $gray-5;

            tr {
                font-weight: bold;
            }
        }

        tr {
            text-align: left;
            font-size: 1rem;
            display: flex;
            padding: 10px 15px;

            @include media(sm) {
                font-size: 1.2rem;
            }
        }

        th:nth-child(1),
        td:nth-child(1) {
            min-width: 50px;

            @include media(sm) {
                min-width: 80px;
            }
        }

        th:nth-child(2),
        td:nth-child(2) {
            min-width: 60px;

            @include media(sm) {
                min-width: 110px;
            }
        }

        th:nth-child(3),
        td:nth-child(3) {
            display: -webkit-box;
            -webkit-line-clamp: 2; 
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }

    &__TableRow {
        background-color: $gray-5;

        &--White {
            background-color: white;
        }
    }

    &__ItemIcon {
        max-height: 18px;
        max-width: 60px;

        @include media(sm) {
            max-width: 110px;
        }
    }

    &__ItemProviderWrapper{
        display: flex;
        position: relative;

        &::after {
            content: '';
            display: inline-block;
            width: 12px;
            height: 12px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url('../#{$publicpath}img/icon-extrnal-link--thin.svg');;
            margin-left: 6px;
        }

        &--HasImage::after {
            width: 16px;
            height: 16px;
        }

        &--NoUrl::after {
            display: none;
        }
    }

    &__ItemProvider {
        text-decoration: none;
        position: absolute;
        width: 100%;
        height: 100%;
    }

    &__ItemDescription {
        font-size: 1.1rem;
        line-height: 1.2;
    }

    &__AboutText {
        font-size: 1.2rem;
        color: $gray-50;
        margin-top: 20px;
        line-height: 1.2;
        max-height: 160px;
        overflow-y: auto;
        scrollbar-color: $button-bg $gray-10;
        border: 1px solid $gray-10;
        padding: 10px;
        border-radius: 3px;

        &--FullHeight {
            max-height: none;
        }

        @include media(sm) {
            padding-right: 20px;
        }

        p {
            margin-bottom: 10px;

            &:last-of-type {
                margin-bottom: 0;
            }

            a {
                color: $button-bg; 
            }
        }
    }

    &__Contact {
        position: absolute;
        font-size: 1.2rem;
        margin-top: 15px;
        line-height: 1.2;
        bottom: 15px;

        @include media(sm) {
            margin-top: 8px;
            font-size: 1.4rem;
        }

        a {
            color: $button-bg; 
            text-decoration: underline;
        }
    }
}
