@import 'Styles/includes';

/**
*
* Tabs
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.Tabs {
    $root: &;

    &__Tabs {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
    }

    &__Header {
        margin-bottom: -1px;
    }

    &__Tab {
        position: relative;
        margin: 0;
        color: $gray-40;
        font-size: 1.1rem;
        font-weight: bold;
        letter-spacing: .8px;
        text-transform: uppercase;
        font-family: $base-font;
        border: 1px solid $gray-10;
        padding: 13px 12px;
        background: $gray-10;
        box-shadow: none;
        cursor: pointer;
        outline: none;
        transition: color .2s;

        @include media(md) {
            padding: 13px 24px;
        }

        svg {
            display: inline-block;
            position: relative;
            margin: -2px 6px -2px 0;
            width: 15px;
            height: 12px;

            * {
                fill: $gray-40;
                transition: fill .2s;
            }
        }

        &:not(#{$root}__Tab--Active):hover {
            color: $text;

            svg * {
                fill: $text;
            }

            @media (hover: none) {
                color: $gray-40;

                svg * {
                    fill: $gray-40;
                }
            }
        }

        &:not(:first-of-type) {
            margin-left: -1px;
        }

        &:first-of-type {
            border-top-left-radius: 4px;
        }

        &:last-of-type {
            border-top-right-radius: 4px;
        }

        &--Active {
            color: $text;
            background: white;

            svg * {
                fill: $text;
            }
        }
    }

    &__Content {
        border: 1px solid $gray-10;
        border-radius: 0px 4px 4px 4px;
        background: white;
    }
}