@import 'Styles/includes';

/**
*
* CardArrangementOffice
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.CardArrangementOffice {
    display: flex;
    align-items: center;
    margin: 0 20px;
    border-top: 1px solid $gray-10;
    background-color: white;
    padding: 20px 20px 20px 0;

    &__Image {
        width: 70px;
        height: 70px;
        border-radius: 35px;
        margin-right: 20px;
    }

    &__Text {
        line-height: 1.25;
        padding-bottom: 4px;

        &--Title {
            font-weight: 600;
        }
    }

    &__Link {
        color: $red;
        text-decoration: none;
        position: relative;

        &:hover {
            text-underline-offset: 2px;
            text-decoration-thickness: 1px;
            text-decoration: underline;
        }
    }
}
