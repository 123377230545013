@import 'Styles/includes';

@keyframes rollUp {
    0% {
      transform: translateY(0); 
    }
    100% {
      transform: translateY(-50%); 
    }
}
  
@keyframes rollDown {
    0% {
      transform: translateY(-50%); 
    }
    100% {
      transform: translateY(0); 
    }
}

.ButtonAI {
    $root: &;

    &__ButtonWrapper {
        padding: 24px 15px 0;

        @include media(md) {
            padding: 48px 0;
        }
        
        &--Wrap {
            @include media(md) {
                max-width: 756px;
                margin: 0 auto;
                padding-bottom: 0;
            }
        }

        &--PageMethod {
            padding: 30px 15px;

            @include media(md) {
                padding-left: 20px;
            }
            @include media(lg) {
                max-width: 1205px;;
                margin: 0 auto;
                padding: 24px 0 0 30px;
            }
        }

        &--PageResourceList {
            padding: 0 15px 16px;

            @include media(md) {
                padding: 0;
            }
        }

        &--PageArrangement {
            padding: 0 0 24px 0;
            

            @include media(md) {
                padding: 0 0 24px 0
            }
        }

        &--PageSubpage {
            padding-top: 0;

            @include media(md) {
                padding-top: 24px;
            }
        }
    }

    &__Button {
        border: 1px solid #E1E1E1;
        border-radius: 2px;
        cursor: pointer;
        background-color: $gray-5;
        padding: 8px;
        position: relative;
        transition: background-color .2s ease-in;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @media only screen and (min-width: 320px) {
            flex-direction: row;
        }

        &:hover {
            @include media(sm) {
                background-color: $gray-15;
            }
        }
    }

    &__ButtonText {
        display: flex;
        margin-top: 8px;
        padding-right: 22px;

        @media only screen and (min-width: 320px) {
            margin-top: 0;
        }

        svg {
            position: absolute;
            right: 10px;
        }

        p {
            max-width: 164px;
            font-size: 12px;
            text-align: left;
            color: $gray-70;

            @media only screen and (min-width: 320px) {
                margin-left: 8px;
            }
        }
    }

    &__Tag {
        min-width: 106px;
        position: relative;
        height: 30px;
        padding: 0 38px 0 10px;
        background-color: $button-bg;
        color: white;
        border: none;
        border-radius: 3px;
        overflow: hidden;
        display: flex;
        align-items: center;
    }

    &__TagTheme {
        white-space: nowrap;
        text-align: left;
    }
    
    &__TagTheme,
    &__TagLabel span {
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
    }

    &__TagLabel {
        position: absolute;
        right: 0;
        top: 0;

        #{$root}__ButtonWrapper--Enter & {
            animation: rollUp 0.5s ease-in-out forwards;
        }

        #{$root}__ButtonWrapper--Leave & {
            animation: rollDown 0.5s ease-in-out forwards;
        }

        span {
            background-color: black;
            width: 30px;
            height: 30px;
            min-height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
