@import 'Styles/includes';

/**
*
* ArrangementConfigurators
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.ArrangementConfigurators {
    $root: &;

    &__TogglerWrapper {
        display: flex;
        justify-content: center;
    }

    &__Toggler {
        outline: none;
        opacity: 1;
        border: none;
        position: relative;
        font-weight: 600;
        font-size: 1.4rem;
        font-family: proxima-nova, Arial;
        letter-spacing: 0.36px;
        color: white;
        background-color: $red;
        border-radius: 4px;
        padding: 16px 46px 16px 24px;
        cursor: pointer;

        #{$root}--Open & {
            margin-bottom: 16px;
        }

        &:after {
            content: '';
            position: absolute;
            width: 22px;
            height: 22px;
            top: 12px;
            right: 16px;
            transition: transform 0.25s ease;
            transform: rotate(-90deg);
            background-image: url('#{$publicpath}img/icon-arrow-white.svg');
            background-repeat: no-repeat;
            background-position: center;

            #{$root}--Open & {
                transform: rotate(90deg);
            }
        }

        &:hover {
            background: $red-60;
            @media (hover: none) {
                background: $red;
            }
        }

        &:active {
            background: $red-30;
        }
    }
}
